import React from "react"
// import styled from "styled-components";
import {Link} from "gatsby";

const InsightsCardPhoto = ({article}) => {

    return (

        <div className="col-12 col-md-8 col-lg-8">
            <div className="card card-row border-card mb-6 card-bg-gray">
                <div className="row no-gutters">
                    <Link className="col-12 col-md-6 order-md-1 bg-cover card-img-left"
                          style={{backgroundImage: "url(" + article.media.localFile.childImageSharp.fluid.src + ")"}}
                       to={article.fields.slug}>
                        {/** Image (placeholder) */}
                        {article.media &&
                        <img src={article.media.localFile.childImageSharp.fluid.src} alt={article.media.name}
                             className="img-fluid d-md-none invisible"/>
                        }
                    </Link>

                    <div className="col-12 col-md-6 order-md-2">
                        <Link className="card-body" to={article.fields.slug}>
                            <h2 className="font-weight-bold spacing">
                                {article.title}
                            </h2>
                            <p className="mb-0 text-muted spacing">
                                {article.description}
                            </p>
                        </Link>
                        <div className="card-meta">
                            <hr className="card-meta-divider"/>

                            <div className="avatar avatar-sm mr-2">
                                <img src={require("../../../static/favicon.ico").default} alt="author"
                                     className="avatar-img rounded-circle"/>
                            </div>

                            <h6 className="text-uppercase text-muted mb-0 ml-auto">
                                {article.area}
                            </h6>
                        </div>
                    </div>
                </div>
                {/** / .row */}
            </div>
        </div>
    )

}

export default InsightsCardPhoto
