import React from "react"
import {Link} from "gatsby";

const InsightsCard = ({article}) => (
    <div className="col-12 col-md-4 col-lg-4">
        <div className="card card-row border-card mb-6 card-bg-gray">
            <Link className="card-body my-auto" to={article.fields.slug}>
                <h2 className="font-weight-bold spacing">
                    {article.title}
                </h2>
                <p className="mb-0 text-muted spacing">
                    {article.description}
                </p>
            </Link>
            <div className="card-meta">
                <hr className="card-meta-divider"/>

                <div className="avatar avatar-sm mr-2">
                    <img src={require("../../../static/favicon.ico").default} alt="author"
                         className="avatar-img rounded-circle"/>
                </div>

                <h6 className="text-uppercase text-muted mb-0 ml-auto">
                    {article.area}
                </h6>
            </div>
        </div>
    </div>

)

export default InsightsCard
