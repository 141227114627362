import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo";
import InsightsFeed from "../components/insights/insights-feed";
import {graphql} from "gatsby";

const InsightsPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang}
                 title="Insights - Tecalis"
                 description={"Consejos y recomendaciones tecnológicas de nuestro equipo de trabajo. Compartiendo conocimiento digital con personas y empresas."}/>
            {/*<Banner/>*/}

            {/** TECH INSIGHTS
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14"
                     style={{
                         backgroundImage: "url('" + require('../images/techinsights_bg.png').default + "')",
                         backgroundPosition: "center",
                         backgroundSize: "cover"
                     }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12">
                            <h1 align="left" className="display-2 mb-8 font-weight-black text-white spacing font-why">
                                Insights T<span className="blink">_</span>
                            </h1>
                        </div>

                        <br/>

                        <div className="col-12 col-lg-8 col-xl-8">
                            <h3 align="left" className="text-white spacing">
                                By Tecalis Team
                            </h3>
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>
            <InsightsFeed data={data} pageContext={pageContext}/>
        </Layout>
    )
}

export default InsightsPage

export const insightsPageQuery = graphql`
   query articlesQuery($limit: Int!, $skip: Int!, $lang: String!){
        allStrapiArticle(
            sort: { fields: [id], order: [DESC] }
            limit: $limit
            skip: $skip
            filter:{
              locale: { eq: $lang }
            }
        ) {
            edges {
                node {
                    id
                    title
                    description
                    content
                    area,
                    # commentario!
                    media {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 720, base64Width: 720) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields{
                        slug
                    }
                }
            }
        }
         cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
    }
    `

